import {
    Component,
    Input
}                        from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-route-back',
   templateUrl:  'route-back.component.html',
   styleUrls:   ['route-back.component.css']
})
export class RouteBackComponent extends BaseComponent
{
    @Input()
    public route: string[] = [];
}