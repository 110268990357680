<!-- <mat-dialog-content>
	<qp-list [title]="title" [attrs]="attrs" [service]="service" (selected)="selected($event)"></qp-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close color="primary" (click)="close()" matTooltip="Close">Close</button>
</mat-dialog-actions> -->
<!-- 
                <tr *ngFor="let device of data | orderBy:propertyName.val:doReverse | paginate: { itemsPerPage: 20, currentPage: currentPage, id: tab7}" (click)="showDetails($event, device)">
                    <td *ngFor="let key of Object.keys(device)" [ngClass]="getColour(key, device[key].val)">
                        {{ device[key].val}}
                    </td> -->
 

<qp-list
   [title]="title"
   [attrs]="attrs"
   [service]="service"
   
   (selected)="select($event)">
</qp-list>
<!-- <qp-list [title]="title" [attrs]="attrs" [service]="service" [order]="attrOrder" [orderDesc]="attrOrderDesc" (selected)="selected($event)"></qp-list> -->