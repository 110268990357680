<button mat-button [matMenuTriggerFor]="menu" matTooltip="Power: {{ state }}">
    <mat-icon [ngClass]="'status ' + state + (blink ? ' blinking' : '')" aria-label="Power state">{{ icon }}</mat-icon>
</button>

<mat-menu #menu="matMenu">
    <!-- ng-template for lazy rendering -->
    <ng-template matMenuContent>
        <!-- <button mat-menu-item *ngFor="let s of states" (click)="select($event, s)">{{ s | titlecase }}</button> -->
        <button mat-menu-item *ngFor="let s of states" [disabled]="state === s || blink" (click)="select($event, s)">{{ s | titlecase }}</button>
        <!-- <button mat-menu-item *ngFor="let icon of iconsSub" [disabled]="state === s || blink" (click)="select($event, s)">{{ s | titlecase }}
            <mat-icon>{{ icon }}</mat-icon>
        </button> -->
    </ng-template>
</mat-menu>