import {
    EmbeddedViewRef,
    Injectable,
    TemplateRef
}                        from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarDismiss,
    MatSnackBarRef
    // TextOnlySnackBar
}                        from '@Material/';

import {
    BaseComponent,
    BaseService
}                        from '@Base/';


// [TBD]
// export { qp_StatusService } from '@Utils-ajs/';


enum StatusMessageLevelEnum {
    // Values must match those in top-level styles.css
    Error = 'error',
    Warn  = 'warn',
    Info  = 'info',
    None  = 'none'
}; // StatusMessageLevelEnum


@Injectable({
    providedIn: 'root'
})
export class StatusMessageService extends BaseService
{
    public  static readonly Level:          typeof StatusMessageLevelEnum = StatusMessageLevelEnum;

    private static readonly config:         MatSnackBarConfig             = {
        horizontalPosition: 'end',//'left',
        verticalPosition:   'top'//'bottom'
    }; // config

    private static readonly CloseLabel:     string                        = "Dismiss";
    private static readonly CssClassBottom: string                        = "status-message-position-bottom";
    private static readonly CssClassTop:    string                        = "status-message-position-top";
    private static readonly Duration:       number                        = 5;//10; // default duration, secs
    private static readonly LevelCssPrefix: string                        = "status-message-";

    private static          CurrentMsg:     BaseComponent | string | TemplateRef<any>;
    private static          Ref:            MatSnackBarRef<any> | undefined;


    constructor(private readonly MatSnackBar: MatSnackBar)
    {
        super();
    }  


    //
    // Public methods
    //
    public clear(): void
    {
        this.set("");
    }


    public get(): BaseComponent | string | TemplateRef<any>
    {
        return StatusMessageService.CurrentMsg;
    }


    public set(
        msg:         BaseComponent | string | TemplateRef<any>,
        level:       StatusMessageLevelEnum = StatusMessageLevelEnum.None,
        duration:    number                 = StatusMessageService.Duration,
        showDismiss: boolean                = true
    ): MatSnackBarRef<any> |  undefined
    {
        return this.show(msg, level, duration, showDismiss);
    }


    //
    // Protected methods
    //
    
    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this.clearMsg(true);
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising StatusMessage service");
    }


    //
    // Private methods
    //
    private clearMsg(dismiss: boolean = false): void
    {
        if (dismiss && StatusMessageService.Ref instanceof MatSnackBarRef) StatusMessageService.Ref.dismiss();

        StatusMessageService.Ref = undefined;
        this.sub = undefined; // clear any subscriptions
    }


    private show(
        d:           BaseComponent | string | TemplateRef<any>,
        level:       StatusMessageLevelEnum,
        t:           number,
        showDismiss: boolean
    ): MatSnackBarRef<any> |  undefined
    // ): MatSnackBarRef<BaseComponent | EmbeddedViewRef<any> | TextOnlySnackBar> | undefined
    {
        if (d) {
            // Clear any existing subscriptions
            if (StatusMessageService.Ref instanceof MatSnackBarRef) {
                // Ignore if same message
                if (StatusMessageService.CurrentMsg === d) return StatusMessageService.Ref
                else                                       this.clearMsg();
            }

            // Will replace any existing open Snack Bar
            // ? this.MatSnackBar.openFromComponent(d)
            const config: MatSnackBarConfig = {
                duration:   (! Number.isNaN(t) && t >= 0)? t * 1000: 0, // convert secs to ms
                panelClass: [
                    level
                        ? StatusMessageService.LevelCssPrefix + level
                        : "",

                    StatusMessageService.config.verticalPosition === 'top'
                        ? StatusMessageService.CssClassTop
                        : StatusMessageService.CssClassBottom 
                ],
                ...StatusMessageService.config
            }; // config

            if (this.MatSnackBar) {                
                StatusMessageService.Ref = 
                    (d instanceof BaseComponent)
                        ? ((this.MatSnackBar.openFromComponent instanceof Function)
                            // 'd as any' needed here
                            ? this.MatSnackBar.openFromComponent(d as any, config)
                            : undefined
                        )
                        
                        :((d instanceof TemplateRef)
                            ? ((this.MatSnackBar.openFromTemplate instanceof Function)
                                ? this.MatSnackBar.openFromTemplate(d, config)
                                : undefined
                            )

                        : ((this.MatSnackBar.open instanceof Function)
                                ? this.MatSnackBar.open(d, showDismiss ? StatusMessageService.CloseLabel : undefined, config)
                                : undefined
                            )
                        );
                StatusMessageService.CurrentMsg = d;
            }

            if (StatusMessageService.Ref instanceof MatSnackBarRef) {
                if (StatusMessageService.Ref.afterDismissed instanceof Function) {
                    this.sub = StatusMessageService.Ref.afterDismissed()
                        .subscribe((d: MatSnackBarDismiss): void => {
                            console.debug("Snack bar dismissed" + (d ? " by action: " + d.dismissedByAction : ""));
                            this.clearMsg();
                        }); // subscribe
                    }

                if (StatusMessageService.Ref.afterOpened instanceof Function) {
                    this.sub = StatusMessageService.Ref.afterOpened()
                        .subscribe((): void => {
                            console.debug("Snack bar opened: " + StatusMessageService.CurrentMsg);
                        }); // subscribe
                }

                if (StatusMessageService.Ref.onAction instanceof Function) {
                    this.sub = StatusMessageService.Ref.onAction()
                        .subscribe((): void => {
                            console.debug("Snack bar action occurred");
                        }); // subscribe
                }
            }
        }
        else {
            this.clearMsg(true);
        }

        return StatusMessageService.Ref;
    }
}