<mat-dialog-content>
	<ng-template #container></ng-template>
</mat-dialog-content>


<mat-dialog-actions>
    @if (! disableCloseButton) {
        <button mat-raised-button mat-dialog-close color="primary" matTooltip="Close"
            (click)="close()">
            Close
        </button>
    }

    @if (enableOkButton) {
        <button mat-raised-button mat-dialog-close color="primary" matTooltip="Ok"
            (click)="ok()">
            Ok
        </button>
    }
</mat-dialog-actions>