import {
    Component,
    EventEmitter,
    Output
}                        from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
    template: ''
})
export class ButtonComponent extends BaseComponent
{
    @Output()
    public pressed: EventEmitter<boolean> = new EventEmitter(); // used to inform parents that button pressed

    
    //
    // Public functions
    //
    public button(): void
    {
        this.pressedFn();
    }


    //
    // Private functions
    //
    private pressedFn(): void
    {
        this.pressed.emit(true);
    }
}