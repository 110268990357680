<button mat-button *ngIf="icon"
    [ngClass]="health" 
    matTooltip="{{
            'Level: '    + (this.level >= 0 ? this.level + '%' : 'unknown')
        + '\nCharging: ' + (this.charging ? 'yes' : 'no')
        + (this.health ? '\nHealth: ' + this.health : '')
    }}"
    matTooltipClass="tooltip-multiline"
    #tt="matTooltip"
    (click)="tt.show()">

    <mat-icon class="icon-1-5x" aria-label="Battery">{{ icon }}</mat-icon>

</button>