import { Injectable } from '@angular/core';
import {
    MatDialog,
    MatDialogRef
}                     from '@angular/material/dialog';

//import { InsightsComponent } from './insights-component';

declare var angular: any;


@Injectable({
    providedIn: 'root'
})
export class InsightsWindowService
{
    private dialogRef: MatDialogRef<InsightsWindowService>;

    private readonly params: any =
    {
        ariaLabel:           'Insights screen',
        clickOutsideToClose: true,
        //title:               'Chart',
        controller:          'qpInsights',
        controllerAs:        'qpInsights',
        disableParentScroll: false,
        escapeToClose:       true,
        locals:              {data: {}},
        //targetEvent:         $event,
        onComplete:          () => { this.screenShowed() },
        onRemoving:          () => { this.screenClosing() },
        onShowing:           () => { this.screenShowing() },
        parent:              angular.element(document.getElementById('map')),
        templateUrl:         '../../fragments/window-insights.html'
    };

    constructor(private readonly dialog: MatDialog)
    { 
        this.initialise();
    }


    //
    // Public Functions
    //
    public showScreen(data: any, event: any): void
    {
        const params = this.params;
        this.params.locals.data = data;
        this.dialogRef = this.dialog.open(params);
        // .then(
        //     function(res: any) { // Finished
        //     },
        //     function() {         // Error
        //     }
        // )
        // .finally(function() {
        //     console.log("Insight screen: closed");
        // });

    } 
     

    private screenShowing(): void
    {
        console.log("Insight screen: displaying");
    }


    private screenClosing(): void
    {
        console.debug("Insight screen: closing");
    }


    private screenShowed(): void
    {
        console.debug("Insight screen: displayed");
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.debug("Initialising InsightsWindow service");

        return true;
    }
}