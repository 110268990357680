import {
    Component,
    Input,
}                           from '@angular/core';

import { IconsMaterial }    from '@Icons/';
import { MapElementLatLng } from '@Map/map-elements/map-element-latlng/';

import { ButtonComponent }  from './button.component';


@Component({
   selector:     'qp-geolocation-button',
   templateUrl:  'geolocation-button.component.html',
   styleUrls:   ['geolocation-button.component.css']
})
export class GeolocationButtonComponent extends ButtonComponent
{
    public readonly icon: string = IconsMaterial.map;

    @Input()
    public geo: MapElementLatLng;

    @Input()
    public str: string = "";


    public get text(): string
    {
        return this.geo ? this.geo.locationStr : this.str;
    }
}