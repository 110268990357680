import { CommonModule }             from '@angular/common';
import { NgModule }                 from '@angular/core';

import { BaseComponent }            from './base.component';
import { BaseWithDynamicComponent } from './base-with-dynamic.component';
// import { BaseService }              from './base.service';


@NgModule({
    imports: [
        CommonModule
    ],
    
    declarations: [
        BaseComponent,
        BaseWithDynamicComponent
    ],

    providers: [
        //BaseService     // don't provide, use as non-Angular base class
    ],

    exports: [
        // Don't export
        //BaseComponent
        //BaseWithDynamicComponent
    ]
})
export class BaseModule { }