import { Injectable }            from '@angular/core';

import { BaseService }           from '@Base/';
// import { MapElementPoint }       from '@Map/map-elements/map-element-point/';
// import { MapElementSize }        from '@Map/map-elements/map-element-size/';
import { ClientConfigService }   from '@Misc/';
import { MobileNetworksService } from '@MobileNetworks/';

// import {
//     ElementHelper,
//     ElementTag,
//     ElementCommon as Element,
//     // Organisation,
//     // Site
// }                                from '@ObjElements/';

// import { ServicesService }       from '@Results/services.service';
// import { TestsService }          from '@Results/tests.service';

import { Icons }                 from './icons.class';


@Injectable({
    providedIn: 'root'
})
export class IconsService extends BaseService
{
    private _icons:   any     = {};
    private _iconSub: boolean = false;


    public static readonly mobilePhoneDefaultIcon: any = {
        x:      30,
        scale:  1,
        url:    Icons.deviceDefault,
        // origin: MapElementPoint.get(0, 0)
    }; // mobilePhoneDefaultIcon
    

    constructor(private readonly ClientConfigService:   ClientConfigService,
                private readonly MobileNetworksService: MobileNetworksService)
                // private readonly ServicesService:       ServicesService,
                // private readonly TestsService:          TestsService)
    {
        super();

        // Don't call any of the injected services in constructor or constructor-called initialise()
        // as they are not guaranteed to be available at this time
    }



    // public static get(d: Element): string | undefined
    // {
    //     switch (ElementHelper.getTag(d)) {
    //         case ElementTag.Organisation: return Icons.organisation;
    //         case ElementTag.Site:         return Icons.site;
    //     } // switch
    //     // if      (d instanceof Organisation) return Icons.organisation;
    //     // else if (d instanceof Site)         return Icons.site;

    //     return undefined;
    // }
    public static get(d: any): string | undefined
    {
        // switch (ElementHelper.getTag(d)) {
        //     case ElementTag.Organisation: return Icons.organisation;
        //     case ElementTag.Site:         return Icons.site;
        // } // switch
        // // if      (d instanceof Organisation) return Icons.organisation;
        // // else if (d instanceof Site)         return Icons.site;

        return undefined;
    }


    //
    // Public Functions
    //
    // public getOperatorIconAsync(plmn?: string, name?: string): Observable<any>
    // {
    //     return new Observable((sub: Subscriber<any>): void => {
    //         this.sub = this.MobileNetworksService.getIcon(plmn, name)
    //             .pipe(take(1)) // auto-unsubscribe after response
    //             .subscribe(
    //                 (d: any): void => {
    //                     sub.next(IconsService.getOperatorIcon(name, d))
    //                     sub.complete();
    //                 }
    //             ); // subscribe dataAvailable
    //     }); // new Observable
    // }


    public getIconDeviceDefault(cb?: Function): any
    {
        return (cb instanceof Function) ? cb(Icons.deviceDefault) : Icons.deviceDefault;
    }


    public getIconMobileNetwork(plmn: string = "", name: string = "", cb: Function): any
    {
        return this.MobileNetworksService.getIcon(plmn, name, (d: any): void => {
            if (! d) d = Icons.deviceDefault;
            if (cb instanceof Function) cb(IconsService.getIconMobileOperator(name, d));
        }); // getIcon

        // return new Observable((sub: Subscriber<any>): void => {
        //     this.sub = this.MobileNetworksService.getIcon(plmn, name)
        //         .pipe(take(1)) // auto-unsubscribe after response
        //         .subscribe(
        //             (d: any): void => {
        //                 sub.next(IconsService.getOperatorIcon(name, d))
        //                 sub.complete();
        //             }
        //         ); // subscribe dataAvailable
        // }); // new Observable
    }


    private static getIconMobileOperator(name: string, iconNew: any): any
    {
        const icon: any = IconsService.getIconMobileOperatorDefault(name);

        if (icon && iconNew) {
            icon.url = (iconNew && iconNew.path) ? Icons.networkBase + iconNew.path : icon.url;

            icon.x   = (iconNew && iconNew.x && iconNew.x > 0) ? iconNew.x : icon.x;
            icon.y   = (iconNew && iconNew.y && iconNew.y > 0) ? iconNew.y : 
                ((iconNew && iconNew.scale && iconNew.scale > 0) ? icon.x * iconNew.scale : icon.y);

            if (icon.x && icon.y) {
                // icon.scaledSize = new MapElementSize( icon.x, icon.y);
                // icon.anchor     = MapElementPoint.get(icon.x, icon.y); // position icon to right of device
                // //icon.anchor     = new google.maps.Point(icon.x / 2, icon.y / 2); // centre over device position
            }
        }
        
        return icon;
    }
    

    public getIconTest(service?: string, test?: string): any
    {
        const icon: any = {
            url:    Icons.deviceTest,
            x: 0,
            y: 0
            // origin: MapElementPoint.get(0, 0)
        }

    //     // First get service icon if possible
    //     let iconNew = service ? this.ServicesService.getIcon(service) : null;
    //     if (! iconNew && test) iconNew = this.TestsService.getIcon(test);

    //     return (iconNew ? iconNew : icon.url);
    return icon.url;
    }


    public getIconTest2(): Promise<any>
    {
        return this.updateIcons().then((d: boolean): any => {
            const i = this.getIconTest();
            i.anim = true;
            return i;
            // return {
            //     url:    Icons.deviceTest,
            //     origin: MapElementPoint.get(0, 0),
            //     anim:   true
            // };
        });

    //     // First get service icon if possible
    //     let iconNew = service ? this.ServicesService.getIcon(service) : null;
    //     if (! iconNew && test) iconNew = this.TestsService.getIcon(test);

    //     return (iconNew ? iconNew : icon.url);
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising IconsService service");
    }


    //
    // Private functions
    //
    private static getIconMobileOperatorDefault(name: string): object
    {
        const icon: any = {
            url:    Icons.deviceDefault,
            // origin: MapElementPoint.get(0, 0)
        }; // icon

        if (name) {
            icon.x     = 30;
            icon.scale = 1.0;
            switch (name) {
                case '3':
                case '3 ita':
                    icon.url = Icons.icon3;
                    icon.scale = 1.2;
                    break;

                case 'ee':
                    icon.url = Icons.iconEe;
                    icon.scale = 1.6;
                    break;

                case 'o2':
                    icon.url = Icons.iconO2;
                    break;

                case 'ooredoo':
                    icon.x = 85
                    icon.url = Icons.iconOoredoo;
                    icon.scale = 0.2;
                    break;

                case 'talkmobile':
                    icon.url = Icons.iconTalkMobile;
                    icon.x = 70;
                    icon.scale = 0.3;
                    break;

                case 'talktalk':
                    icon.url = Icons.iconTalkTalk;
                    break;

                case 'tesco':
                    icon.url = Icons.iconTescoMobile;
                    icon.scale = 1.5;
                    break;

                case 'virgin':
                    icon.url = Icons.iconVirgin;
                    icon.x = 40;
                    icon.scale = 0.7;
                    break;

                case 'vodafone':
                    icon.url = Icons.iconVodafone;
                    break;

                default:
                    delete icon.x
            }
        }

        // Set anchor point
        if (icon.x && icon.x > 0 && icon.scale && icon.scale > 0) {
            icon.y          = icon.x * icon.scale;
            // icon.scaledSize = new MapElementSize(icon.x, icon.y);
            // icon.anchor     = MapElementPoint.get(icon.x / 2, icon.y / 2);
        }

        return icon;
    }


    private updateIcons(): Promise<boolean>
    {
        return new Promise((res, rej) => {
            if (this._iconSub) return res(true);
            else {
                this.sub = this.ClientConfigService.icons$.subscribe((d: any): void => {
                    this._icons = d;
                    console.debug(d);
                }); // subscribe
                return res(this._iconSub = true);
            }
        }); // Promise
    }
}