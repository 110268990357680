
<div class="content" *ngFor="let image of images" >
    <a href="{{ image.path }}">
        <!-- <img ngSrc="{{ image.path }}" alt="{{ image.name ? image.name : 'image' }}" height="150" width="150" priority  /> -->
        <img ngSrc="{{ image.path }}" alt="{{ image.name ? image.name : 'image' }}" fill priority/>
    </a>
</div>
<!-- ngSrcset="100w, 200w, 300w" sizes="50vw" -->

<!---
<mat-card class="list-card">
-->
  <!-- <mat-card-header>
    <mat-card-title>List of Images</mat-card-title>
  </mat-card-header> -->
  <!--<mat-card-content>
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let image of images" class="list-item">
        <img src="{{ image.path }}" alt="{{ image.name ? image.name : 'image' }}" height="100px"/>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
-->