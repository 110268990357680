<table mat-table *ngIf="data" [dataSource]="data">

    <!-- Key -->
    <ng-container matColumnDef="k">
        <td mat-cell [fxFlex]="'1 0 ' + spaceKey" *matCellDef="let mc">
            {{ mc.k }}
            <!-- <b>{{ mc.k }}</b> -->
        </td>
    </ng-container>

    <!-- Value -->
    <ng-container matColumnDef="v"> 
        <!-- <td mat-cell *matCellDef="let mc"> -->
            <td mat-cell [fxFlex]="'1 1 ' + spaceValue" *matCellDef="let mc">
            <!-- {{ mc.v }} -->
              
            <!-- mc.c is CSS class -->
            <b [ngClass]="mc.c">{{ mc.v }}</b>
        </td>
    </ng-container>


    <tr mat-row *matRowDef="let mr; columns: ['k', 'v']"></tr>

</table>