import { Injectable }         from '@angular/core';
import { Observable }         from 'rxjs';

import { BaseService }        from '@Base/';
import { GeolocationCommon }  from '@Common/';
import {
    MessageProcessingService,
    MessageService
}                             from '@Messaging/';

import { 
    ElementHelperCommon,
    ElementType
}                             from '@ObjElements/element/element/';

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService extends BaseService
{
    private static readonly initGetGeolocation: boolean = false;

    private static readonly geoLocOptions = {
        enableHighAccuracy: true,
        timeout:            5 * 1000, // secs
        maximumAge:         0
    }; // geoLocOptions

    private static readonly GeolocationCommonAttrs = {
        [GeolocationCommon.attrs._accuracy]:  'accuracy',
        [GeolocationCommon.attrs._altitude]:  'altitude',
        // [GeolocationCommon.attrs._accuracy]:  'altitudeAccuracy',
        [GeolocationCommon.attrs._bearing]:   'heading',
        [GeolocationCommon.attrs._latitude]:  'latitude',
        [GeolocationCommon.attrs._longitude]: 'longitude',
        [GeolocationCommon.attrs._speed]:     'speed'
    }; // GeolocationCommonAttrs


    private _posLocal: any = undefined; // stored location obtained via geolocation


    constructor(private readonly MessageProcessingService: MessageProcessingService,
                private readonly MessageService:           MessageService)
    {
        super();
    }


    //
    // Getters and setters
    //
    public get geolocation(): any
    {
        return this._posLocal;
    }

    public set geolocation(pos: any)
    {
        this._posLocal = pos;
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising GeoLocation service");
        if (GeoLocationService.initGetGeolocation) {
            this.getGeolocation(true, (e: object, pos: object): void => {
                this.sendGeolocation(); // send current browser geolocation

                // Now got first geolocation, subscribe for further geolocation requests
                const s: Observable<any> | undefined = this.MessageProcessingService.getObs$(this.MessageService.messages.msgTypesInfo.geolocation);
                if (s instanceof Observable) this.sub = s.subscribe((): void => {
                    this.sendGeolocation();
                }); // subscribe
            }); // getGeolocation
        }
    }

    
    //
    // Private methods
    //
    private getGeolocation(forceLookup: boolean, cb: Function): any
    {
        function geolocSuccess(pos: any, cb: Function): void
        {
            console.info("Geolocation retrieved successfully");
    
            // if (pos) this.geolocation = pos;
            if (cb instanceof Function) cb(undefined, pos);
        }

        return (! forceLookup && this.geolocation)
            ? geolocSuccess(this.geolocation, cb)  // callback immediately with stored location

            : (navigator && navigator.geolocation && navigator.geolocation.getCurrentPosition instanceof Function
                ? navigator.geolocation.getCurrentPosition( // request geolocation from browser
                    // Sucess
                    (pos: any): any => {
                        return geolocSuccess(this.geolocation = pos, cb)
                    },

                    // Error
                    (e: any): any => {
                        console.warn("Geolocation retrieval failed: "
                            + (e ? e.code + ", " + e.message : "not supported by browser"));
                
                        if (cb instanceof Function) cb(true);
                    },

                    // Options
                    GeoLocationService.geoLocOptions
                ) // navigator.geolocation.getCurrentPosition

                : undefined
            );
    }


    // private geolocSuccess(pos: any, cb: Function): void
    // {
    //     console.info("Geolocation retrieved successfully");

    //     if (pos) this.geolocation = pos;
    //     if (cb instanceof Function) cb(undefined, pos);
    // }


    // private geolocError(e: any, cb: Function): void
    // {
    //     console.warn("Geolocation retrieval failed: "
    //         + (e ? e.code + ", " + e.message : "not supported by browser"));

    //     if (cb instanceof Function) cb(true);
    // }


    private sendGeolocation(): Observable<any> | undefined
    {
        const obj = (this.geolocation && this.geolocation.coords)
            ? ElementHelperCommon.get(this.geolocation.coords, ElementType.Geolocation, GeoLocationService.GeolocationCommonAttrs, undefined, true)
            : undefined;
        if (obj instanceof GeolocationCommon) (obj as GeolocationCommon).time = this.geolocation.timestamp;
        // [GeolocationCommon.attrs._time] = this.geolocation.timestamp;

        // const obj2 = this.geolocation && this.geolocation.coords
        //     ? {
        //         accuracy:         this.geolocation.coords.accuracy,
        //         altitude:         this.geolocation.coords.altitude, 
        //         altitudeAccuracy: this.geolocation.coords.altitudeAccuracy,
        //         heading:          this.geolocation.coords.heading,
        //         latitude:         this.geolocation.coords.latitude,
        //         longitude:        this.geolocation.coords.longitude,
        //         speed:            this.geolocation.coords.speed,
        //         timestamp:        this.geolocation.timestamp
        //     }
        //     : undefined;

        return obj ? this.MessageService.sendMsg(this.MessageService.messages.msgTypesInfo.geolocation, obj) : undefined;
    }
}