import {
    Component,
    Input
}                        from '@angular/core';

import { DeviceCommon }  from '@ObjElements/devices/';
// import { DeviceCommon }  from '@ObjElements/devices';
import { IconsMaterial } from '@Icons/';
import { MenuComponent } from './menu.component';


@Component({
   selector:     'qp-power-button',
   templateUrl:  'power-button.component.html',
   styleUrls:   ['power-button.component.css']
})
export class PowerButtonComponent extends MenuComponent
{
    public readonly icon:     string   = IconsMaterial.power;
    // public readonly iconsSub: string[] = [
    //     IconsMaterial.power_on,
    //     IconsMaterial.power_off,
    //     IconsMaterial.power_reset
    // ];

    public readonly states: string[] = [
        DeviceCommon.powerState.on,
        DeviceCommon.powerState.off,
        DeviceCommon.powerState.reset
    ];

    @Input()
    public blink: boolean = false;

    @Input()
    public state: string = DeviceCommon.powerState.unknown;
}