import { Injectable }       from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree
}                           from '@angular/router';
import {
    Observable,
    Subject,
    Subscription,

    take
}                           from 'rxjs';

import { routeNames }       from '../../app.routing.names';

import { BaseGuard }        from '../base-guard.service';

import { 
    AuthenticationRsp,
    AuthenticationService
}                           from '../authentication.service';
import { 
    User,
    UserService
}                           from '../user.service';


@Injectable({
    providedIn: 'root'
})
export class SecureAuthenticationGuard extends BaseGuard
{
    // private                 _user:        User;


    constructor(Router:                Router,

                AuthenticationService: AuthenticationService,
                UserService:           UserService)
    {
        super(Router, AuthenticationService, UserService);

        if (this.UserService && this.UserService.user$ instanceof Observable) {
            this.sub = this.UserService.user$.subscribe((d: User | undefined): void => {
                if (d instanceof User) this._user = d;
            }); // subscribe
        }
    }


    // Override
    public override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> | UrlTree
    {
        if (this.AuthenticationService && this.AuthenticationService.login instanceof Function) {
            const obs: Observable<AuthenticationRsp> | undefined = this.AuthenticationService.login(this._user);
            if (obs instanceof Observable) {
                const success$: Subject<boolean | UrlTree> = new Subject<boolean | UrlTree>();
                this.sub = obs
                    .pipe(take(1))
                    .subscribe((d: AuthenticationRsp): void => {
                        if (d && d.user instanceof User) {
                            if (d.user.isValid && !!d.user.landingPage) {
                                success$.next(true);
                            }
                            else {
                                this.AuthenticationService.logout(false);
                                success$.next(this.Router.createUrlTree([routeNames.login]));
                            }
                        }
                        else {
                            success$.next(this.Router.createUrlTree([routeNames.login]));
                        }
                    }); // subscribe

                return success$.asObservable();
            }
        }
        else {
            console.warn("No valid user; redirecting to login page (2)");
            console.warn(this._user);

            // Here, not logged in so redirect to login page with the return url

            // Don't do direct navigation as this causes routing errors as previous navigation which trigger
            // this guard is still incomplete.  Return urlTree instead
            //this.Router.navigate([routeNames.login], { queryParams: { returnUrl: state.url }});
            return this.Router.createUrlTree([routeNames.login], { queryParams: { returnUrl: state.url }});
        }

        return false;
    }
}