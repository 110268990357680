import { Injectable }       from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
}                           from '@angular/router';
import {
    Observable,
    Subject,
    Subscription,

    take
}                           from 'rxjs';

import { routeNames }       from '../../app.routing.names';

import { BaseService }      from '@Base/';

import { 
    AuthenticationRsp,
    AuthenticationService
}                           from '../authentication.service';

import { 
    User,
    UserService
}                           from '../user.service';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard extends BaseService implements CanActivate
{
    public static readonly returnUrlKey = 'returnUrl';

    private _checked: boolean = false;
    private _user:    User | undefined;


    constructor(private readonly Router:                Router,
                private readonly AuthenticationService: AuthenticationService,
                private readonly UserService:           UserService)
    {
        super();

        if (this.UserService && this.UserService.user$ instanceof Observable) {
            this.sub = this.UserService.user$.subscribe((d: User | undefined): void => {
                if (! ((this._user = d) instanceof User)) this._checked = false;
            }); // subscribe
        }
    }


    //
    // Getters
    //
    private get isChecked(): boolean
    {
        return this._checked;
    }


    //
    // Public methods
    //
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> | UrlTree
    {
        // if user in localStorage, need to send to server to validate the first time after a re-load
        if (this._user instanceof User && this._user.isValid) {
            // Valid user - if here, token not expired as user service would have informed otherwise
            return this.isChecked ? true : this.processUser(this._user);
        }
        else {
            // No valid user

            console.warn("No valid user; redirecting to login page (1)");
            console.warn(this._user);

            // Here, not logged in so redirect to login page with the return url

            // Don't do direct navigation as this causes routing errors as previous navigation which trigger
            // this guard is still incomplete.  Return urlTree instead
            //this.Router.navigate([routeNames.login], { queryParams: { returnUrl: state.url }});
            return this.Router.createUrlTree([routeNames.login], { queryParams: { returnUrl: state.url }});
        }
    }


    //
    // Private methods
    //
    private processUser(u: User): boolean | Observable<boolean | UrlTree>
    {
        // Need to check login with server
        if (this.AuthenticationService && this.AuthenticationService.login instanceof Function) {
            const obs: Observable<AuthenticationRsp> | undefined = this.AuthenticationService.login(u);
            if (obs instanceof Observable) {
                const success$: Subject<boolean | UrlTree> = new Subject<boolean | UrlTree>();
                const sub: Subscription = obs
                    .pipe(take(1))
                    .subscribe((d: AuthenticationRsp): void => {
                        success$.next(
                            d && d.user instanceof User && d.user.isValid
                            ? (
                                !!d.user.landingPage
                                    ? this.Router.createUrlTree([d.user.landingPage])
                                    : this._checked = true
                            )
                            : false
                        ); // next
                    }); // subscribe
                this.sub = sub;

                return success$.asObservable();
            }
        }

        return false;
    }
}