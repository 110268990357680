import { Injectable }  from '@angular/core';

import { BaseService } from '@Base/';


@Injectable({
    providedIn: 'root'
})
export class SettingsService extends BaseService
{
    constructor()
    { 
        super();

        this.initialise();
    }


    //
    // Public methods
    //
    public getSettings(setting: any): any
    {
        //console.debug("[TBD] getSettings()");

        return undefined;
    }


    //
    // Protected methods
    //

    // OVerride
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Settings service");
    }
}