import { CommonModule }  from '@angular/common';
import { NgModule }      from '@angular/core';
import { RouterModule }  from '@angular/router';

import { MainComponent } from './main.component';

import { mainRoutes }    from './main.routes';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(mainRoutes) // needed for lazy loading
    ],
    
    declarations: [
        MainComponent
    ],

    providers: [
    ],

    exports: [
    ]
})
export class MainModule { }