import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-progress-spinner-c',
   templateUrl:  'progress-spinner-dialog.component.html',
   styleUrls:   ['progress-spinner-dialog.component.css']
})
export class ProgressSpinnerDialogComponent extends BaseComponent
{

}