import { Injectable }            from '@angular/core';
import {
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpRequest
}                                from '@angular/common/http';
import {
    Observable,

    catchError,
    throwError
}                                from 'rxjs';

import { BaseService }           from '@Base/';

import { AuthenticationService } from './authentication.service';


@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor extends BaseService implements HttpInterceptor
{
    constructor(private readonly AuthenticationService: AuthenticationService)
    {
        super();
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(request).pipe(catchError(e => {
            if (e.status === 401) {
                console.log("401 received; redirecting to login page");
                // auto logout if 401 response returned from api
                this.AuthenticationService.logout();
                location.reload();//location.reload(true);
            }
            
            const error = e.error.message || e.statusText;
            console.log("Throwing HTTP error");
            return throwError(e);
        }))
    }
}