import { Subscription } from 'rxjs';


export class Base 
{
    private _sub:    Subscription = new Subscription();
    private _timers: number[]     = [];


    public constructor()
    {
        // Do nothing
    }


    //
    // Getters and setters
    //
    protected get sub(): Subscription | undefined
    {
        return this._sub;
    }

    protected set sub(s: Subscription | undefined)
    {
        if (s) {
            if (s instanceof Subscription) this._sub.add(s);
        }
        else {
            this._sub.unsubscribe();
            this._sub = new Subscription();
        }
    }


    protected set timer(s: number | undefined)
    {
        if (s) {
            this._timers.push(s);
        }
        else {
            for (let t = 0, max = this._timers.length; t++; t < max) {
                if (this._timers[t]) {
                    clearTimeout(this._timers[t]);
                    this._timers.splice(t, 1);
                }
            } // for
        }
    }


    //
    // Protected functions
    //
    protected cleanUp(): void
    {
        this.sub   = undefined; // will trigger unsubscribe via setter
        this.timer = undefined; // will tigger clearTimer() on all timers via setter
    }


    protected initialise(): void
    {
        // Do nothing
    }
}