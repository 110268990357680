import { Injectable }  from '@angular/core';
import {
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpRequest
}                      from '@angular/common/http';
import { Observable }  from 'rxjs';

import { BaseService } from '@Base/';

import { 
    User,
    UserService
}                      from './user.service';


@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor extends BaseService implements HttpInterceptor 
{
    private _user: User;


    constructor(private readonly UserService: UserService)
    {
        super();

        if (this.UserService && this.UserService.user$ instanceof Observable) {
            this.sub = this.UserService.user$.subscribe((d: User | undefined): void => {
                if (d instanceof User) this._user = d; 
            }); //
        }
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Add authorisation header with jwt token if available
        if (this._user && this._user.token) {
            request = request.clone(
                {
                    setHeaders: { Authorization: `Bearer ${this._user.token}` }
                }
            );  // request clone
        }

        return next.handle(request);
    }
}