<div class="mat-button">
    <button  mat-button
        [ngClass]="getCssClass()"
        matTooltip="Technology: {{ technology }}"
        matTooltipClass="tooltip-multiline"
        #tt="matTooltip"
        (click)="tt.show()">

        <mat-icon class="icon-2x" aria-label="Mobile cell">{{ icon }}</mat-icon>
        <!-- "is5g() ? 'nr' : technology" -->

    </button>
</div>