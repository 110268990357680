import { Component }       from '@angular/core';

import { IconsMaterial }   from '@Icons/';

import { ButtonComponent } from './button.component';


@Component({
   selector:     'qp-image-button',
   templateUrl:  'image-button.component.html',
   styleUrls:   ['image-button.component.css']
})
export class ImageButtonComponent extends ButtonComponent
{
   public readonly icon: string = IconsMaterial.image;
}