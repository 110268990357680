<!-- <mat-toolbar class="mat-elevation-z10" fxLayout="row" color="primary" id="header" fxLayoutAlign="space-between center"> -->
<mat-toolbar class="mat-elevation-z10" fxLayout="row" id="header" fxLayoutAlign="space-between center">
    <div fxFlexAlign="start">
        <div fxHide.gt-xs style="display: inline-block">
            <qp-sidebar-menu (selected)="onClick($event)"></qp-sidebar-menu>
        </div>
        
        <div style="display: inline-block">
            <a href="{{ companyUrl }}" target="_blank" class="image-png">
                <img src="{{ imgSrcLarge }}" fxHide.lt-sm height="50vw" alt="Spry Fox Networks Icon">
                <img src="{{ imgSrcSmall }}" fxHide.gt-xs height="50vw" alt="Spry Fox Networks Icon">
            </a>

                <!--<a href="http://www.pentaconsulting.com/" target="_blank" class="image-png">
                <img src="${require('./assets/images/organisation/penta_full.png)}" height="50vw" alt="Penta Consulting Icon"></a>-->

            <a href="/"><h1>{{ name }}</h1></a>
        </div>
    </div>


    <!-- Standard toolbar-->
    <div fxFlexAlign="end center" fxHide.lt-md>
        <qp-header-devices       *ngIf="all || devices"></qp-header-devices>
        
        <!-- <qp-header-device-groups *ngIf="false && all || deviceGroups"></qp-header-device-groups> -->

        <qp-header-notifications *ngIf="all || notifications"></qp-header-notifications>

        <qp-header-settings      *ngIf="false && all || settings"></qp-header-settings>

        <qp-header-display-mode  *ngIf="all || displayMode"></qp-header-display-mode>

        <qp-header-user          *ngIf="all || user"></qp-header-user>

        <qp-header-about         *ngIf="all || about"></qp-header-about>
    </div>


    <!-- Small screen toolbar-->
    <div fxFlexAlign="end center" fxHide.gt-sm>

        <!-- <a class="valign-center" role="button" mat-button [matMenuTriggerFor]="menu" aria-label="Additional options" matTooltip="Additional options">
            <mat-icon>{{ menuSmallIcon }}</mat-icon>
        </a> -->

        <qp-header-devices *ngIf="all || devices"></qp-header-devices>
        <qp-header-user    *ngIf="all || user"></qp-header-user>

        @if (displayModeOnly) {
            <div *ngIf="all || displayMode">
                <qp-header-display-mode></qp-header-display-mode>
            </div>
        }
        @else {
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Additional options" matTooltip="Additional options">
                <mat-icon>{{ menuSmallIcon }}</mat-icon>
                <!-- <span>Additional options</span> -->
            </button>

            <mat-menu #menu="matMenu">
                <ng-template matMenuContent>
                    <!-- <div mat-menu-item *ngIf="all || devices"
                        (click)="$event.stopPropagation()"
                    >
                        <qp-header-devices></qp-header-devices>
                    </div> -->

                    <!-- <div mat-menu-item *ngIf="false && all || deviceGroups"
                        (click)="$event.stopPropagation()"
                    >
                        <qp-header-device-groups></qp-header-device-groups>
                    </div> -->

                    <div mat-menu-item *ngIf="all || notifications"
                        (click)="$event.stopPropagation()"
                    >
                        <qp-header-notifications></qp-header-notifications>
                    </div>

                    <div mat-menu-item *ngIf="false && all || settings"
                        (click)="$event.stopPropagation()"
                    >
                        <qp-header-settings></qp-header-settings>
                    </div>

                    <div mat-menu-item *ngIf="all || displayMode"
                        (click)="$event.stopPropagation()"
                    >
                        <qp-header-display-mode></qp-header-display-mode>
                    </div>
        <!-- 
                    <div mat-menu-item *ngIf="all || user"
                        (click)="$event.stopPropagation()"
                    >
                        <qp-header-user></qp-header-user>
                    </div> -->

                    <div mat-menu-item *ngIf="all || about"
                        (click)="$event.stopPropagation()"
                    >
                        <qp-header-about></qp-header-about>
                    </div>
                </ng-template>
            </mat-menu>
        }
    </div>
</mat-toolbar>
