import { Component }           from '@angular/core';
import {
    Observable,
    Subscription,
    
    timer
}                              from 'rxjs';

import { DialogService }       from '@Misc/Services/';

import { HeaderBaseComponent } from './header-base.component';


// Need to use this to effectively pass non-DI constructor args from child objects,
// as Angular does DI on constructor args
export interface HeaderBadgeComponentData
{
    readonly name:       string,
    readonly component:  any,
             observable: Observable<number> | undefined
};


@Component({
   template: ''
})
export class HeaderBadgeComponent extends HeaderBaseComponent
{
    private   static readonly colourAccent:   string                    = "accent";
    private   static readonly colourPrimary:  string                    = "primary";
    private   static readonly colourWarn:     string                    = "warn";

    protected static readonly colourIncrease: string                    = HeaderBadgeComponent.colourAccent;
    protected static readonly colourDecrease: string                    = HeaderBadgeComponent.colourWarn;
    private   static readonly timeout:        number                    = 5; // secs

    private                   _colour:        any                       = HeaderBadgeComponent.colourPrimary;
    private                   _data:          HeaderBadgeComponentData;
    private                   _num:           number                    = 0;
    private                   _timer:         Subscription | undefined  = undefined;


    constructor(DialogService: DialogService)
    {
        super(DialogService);
    }


    // Derived classes need to override this
    protected getData(): HeaderBadgeComponentData | undefined
    {
        console.warn("Derived class needs to override this");

        return undefined;
    }


    //
    // Getters and setters
    //
    public get colour(): any
    {
        return this._colour;
    }

    public set colour(c: any)
    {
        this._colour = c;
    }


    // Override
    protected override get component(): any
    {
        return this.data ? this.data.component : undefined;
    }


    protected get data(): HeaderBadgeComponentData
    {
        return this._data;
    }

    private set dataI(d: HeaderBadgeComponentData | undefined)
    {
        this._processData(d ? (this._data = d) : this.data);
    }

   
    public get num(): number
    {
        return this._num;
    }

    public set num(n: number)
    {
        this._num = n;
    }


    private get obs(): Observable<number> | undefined
    {
        return this.data ? this.data.observable : undefined;
    }


    private get title(): string
    {
        return this.data ? this.data.name : "<Unknown>";
    }


    private get timerS(): Subscription | undefined
    {
        return this._timer;
    }

    private set timerS(t: Subscription | undefined)
    {
        this._clearTimer();
        this._timer = t;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this._clearTimer();
    }


    protected getColour(newV: number, currentV: number): any
    {
        return ((newV > currentV)
                ? HeaderBadgeComponent.colourIncrease
                
                : (( newV < currentV)
                    ? HeaderBadgeComponent.colourDecrease
                    : undefined
                )
        ); // return
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        this.dataI = this.getData();

        console.debug("Initialising " + this.title + " component");
    }


    protected update(num: number, updateColour: boolean = true): void
    {
        if (num != this.num) {
            if (num >= 0) {
                this.updateColour(updateColour ? this.getColour(num, this.num) : undefined);
                this.num = num;
            }
            else {
                this.num = 0;
            }
        }
    }


    protected updateColour(col: any): void
    {
        if (col) {
            this.colour = col; // do before 'num' line below

            // Modify badge colour to indicate device number changed
            this.timerS = timer(HeaderBadgeComponent.timeout * 1000)
                .subscribe(() => {
                    this.colour = HeaderBadgeComponent.colourPrimary;
                    this.timerS = undefined;
                }); // subscribe
        }
    }


    //
    // Private methods
    //
    private _clearTimer(timer: Subscription | undefined = this._timer): void
    {
        if (timer instanceof Subscription && ! timer.closed) timer.unsubscribe();
        timer = undefined;
    }


    private _processData(data?: HeaderBadgeComponentData): void
    {
        this._clearTimer();

        //if (this.obs) this.sub = null; // close existing subscription(s)
        //this._data = data ? data : this.data;

        if (this.obs instanceof Observable) this.sub = this.obs
            .subscribe((num: number): void => {
                this.update(num);
            }); // subscribe
        //else this.update(0);
    }
}