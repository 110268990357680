<!-- <qp-sidebar id="desktop" fxHide.xs="true"></qp-sidebar> -->
<!-- <div class="full" id="desktop" fxShow fxHide.lt-md> -->
<div class="full" id="desktop">
    <router-outlet (activate)="onRouteActivated($event)"></router-outlet>
</div>

<!-- <div class="full" fxHide fxShow.lt-md>
    <p>You have received a file called "cat-picture.jpeg".</p>

<button mat-raised-button (click)="openBottomSheet()">Open file</button>
</div> -->
