import {
    Injectable,
    OnDestroy
}               from '@angular/core';

import { Base } from './base.class';


@Injectable({
    providedIn: 'root'
})
export class BaseService extends Base implements OnDestroy
{
    public constructor()
    {
        super();

        this.initialise();
    }


    public ngOnDestroy(): void
    {
        this.cleanUp(); // In parent
    }
}