import { CommonModule }     from '@angular/common';
// import { downgradeComponent } from '@angular/upgrade/static';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule }         from '@angular/core';
import { RouterModule }     from '@angular/router';

// qp-header and qp-footer
import { HeaderModule }     from '@Header/';

import { AppComponent }     from './app.component';


// declare let angular: angular.IAngularStatic;
// children:     sidebarRoutes //tabRoutes


// AppComponent is bootstrap component for app


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,

        HeaderModule
    ],
    
    declarations: [
        AppComponent
    ],

    providers: [
    ],

    exports: [
        AppComponent
    ]
})
export class AppModule { }


// angular
//     .module('qpProMobileApp')
//     .directive('qpApp', downgradeComponent({ component: AppComponent }));