import {
   Component,
   Input
}                          from '@angular/core';

import { IconsMaterial }   from '@Icons/';

import { ButtonComponent } from './button.component';
    
import { Image }           from '@ObjElements/';


@Component({
   selector:     'qp-image-list',
   templateUrl:  'image-list.component.html',
   styleUrls:   ['image-list.component.css']
})
export class ImageListComponent extends ButtonComponent
{
   @Input()
   public images: Image[];
}