import { CommonModule }   from '@angular/common';
import { NgModule }       from '@angular/core';

import { MessageService } from './message.service';


@NgModule({
    imports: [
        CommonModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class MessagingModule { }