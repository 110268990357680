import { Component }           from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators
}                              from '@angular/forms';
import {
    ActivatedRoute,
    Router
}                              from '@angular/router';
import {
    Observable,

    take
}                              from 'rxjs';

import { routeNames }          from '../../app.routing.names';

import { BaseComponent }       from '@Base/';
import { HeaderService }       from '@Header/';
import { MessageService }      from '@Messaging/';

import { AuthenticationGuard } from './login-authentication-guard.service';
import {
    AuthenticationRsp,
    AuthenticationService
}                              from '../authentication.service';
import { 
    User,
    UserService
}                              from '../user.service';


@Component({
    selector:     'qp-login',
    templateUrl:  'login.component.html',
    styleUrls:   ['login.component.css']
})
export class LoginComponent extends BaseComponent
{
    private static readonly errMsgLoginError:  string     = "Login error";
    private static readonly errMsgLoginNoData: string     = "No login data received";
    private static readonly minLength:         number     = 1;

    public  hidePassword:                      boolean    = true;
    public  loading:                           boolean    = false;
    public  loginForm:                         FormGroup;
    public  submitted:                         boolean    = false;

    private _connected:                        boolean    = false;
    private _errorMsg:                         string     = "";
    private _returnUrl:                        string;


    constructor(private readonly ActRoute:              ActivatedRoute,
                private readonly FormBuilder:           FormBuilder,
                private readonly Router:                Router,

                private readonly AuthenticationService: AuthenticationService,
                private readonly HeaderService:         HeaderService,
                private readonly MessageService:        MessageService,
                private readonly UserService:           UserService)
    {
        super();

        this.HeaderService.user = false; // disable all buttons on header bar

        // Redirect to home if already logged in
        if (this.UserService && this.UserService.user$ instanceof Observable)
            this.sub = this.UserService.user$
                .pipe(take(1))
                .subscribe((d: User | undefined): void => {
                    // if (d instanceof User && d.isValid) this.Router.navigate([routeNames.main]);
                }); // subscribe

        // Enable/disable login form depending on server connection status
        if (this.MessageService && this.MessageService.connected$ instanceof Observable)
            this.sub = this.MessageService.connected$.subscribe((d: boolean): void => {
                this.connectedI = d;
            }); // subscribe
    }


    //
    // Override
    //
    override ngOnInit() 
    {
        super.ngOnInit();

        this.loginForm = this.FormBuilder.group(
            {
                username: [ '', [Validators.required, Validators.minLength(LoginComponent.minLength)] ],
                password: [ '', [Validators.required, Validators.minLength(LoginComponent.minLength)] ]
            }
        ); // FormBuilder.group
        // if (this.loginForm instanceof FormGroup) {
        //     if (this.connected) this.loginForm.enable();
        //     else                this.loginForm.disable();
        // }

        // Get return url from route parameters or default to '/'
        this._returnUrl = this.ActRoute.snapshot.queryParams[AuthenticationGuard.returnUrlKey] || routeNames.main;
    }


    // Getters and setters
    public get connected(): boolean
    {
        return this._connected;
    }

    private set connectedI(d: boolean)
    {
        this._connected = d;
        // if (this.loginForm instanceof FormGroup) {
        //     if (this.connected) this.loginForm.enable();
        //     else                this.loginForm.disable();
        // }
    }


    public get errorMsg(): string
    {
        return this._errorMsg;
    }

    private set errorMsgI(msg: string)
    {
        if (this._errorMsg = msg) this.loginForm.value.password = undefined; // blank password
    }


    public get f(): any
    {
        return this.loginForm instanceof FormGroup ? this.loginForm.controls : undefined;
    }


    //
    // Public methods
    //
    public onFocus(event?: any)
    {
        this.errorMsgI = "";
    }


    public onSubmit(): void
    {
        this.submitted = true;

        if (this.loginForm.value.username && this.loginForm.value.password) {
            this.errorMsgI = "";

            if (this.AuthenticationService && Object(this.AuthenticationService).login instanceof Function) {
                this.loading = true;

                const obs: Observable<AuthenticationRsp> | undefined
                     = this.AuthenticationService.login(this.loginForm.value.username, this.loginForm.value.password)
                if (obs instanceof Observable) this.sub = obs
                    .pipe(take(1)) // auto-unsubscribe after response
                    .subscribe(
                        (d: AuthenticationRsp): void => {
                            this.loading = false;

                            if (d) {
                                if (d.user instanceof User) {
                                    this.errorMsgI = ""; // blank any error msg
                                    const url: String =
                                        d.user.landingPage
                                            ? d.user.landingPage
                                            : this._returnUrl;

                                    console.debug("Navigating from login page to '" + url + "'");
                                    this.Router.navigate([url]); 

                                    // this.Router.navigate(
                                    //     [url],
                                    //     { state: { test: true, url: "https://www.spryfoxnetworks.com" } }
                                    // ); 
                                }
                                else {
                                    this.errorMsgI = d.msg ? d.msg : "";
                                    console.warn("Login error: " + this.errorMsg + " (1)");
                                }
                            }
                            else {
                                this.errorMsgI = JSON.stringify(LoginComponent.errMsgLoginNoData);
                                console.warn("Login error: " + this.errorMsg + " (2)");
                            }
                        }

                        // (error: object): void => {
                        //     this.errorMsgI = JSON.stringify(error);
                        //     console.log("Login error: " + this.errorMsg + " (3)");
                        //     this.loading = false;
                        // }
                    ); // subscribe
            }
            else {
                this.errorMsgI = JSON.stringify(LoginComponent.errMsgLoginError);
                console.error("AuthenticationService not available: " + this.loginForm.value.username);
                console.warn("Login error: " + this.errorMsg + " (3)");
            }
        }
    }
}