<div id="loginform">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div>
            <mat-form-field appearance="outline" required="true" hideRequiredMarker="true">
                <mat-label>Username</mat-label>
                <input matInput name="Username" type="text" autocomplete="on" formControlName="username" (focus)="onFocus()" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            </mat-form-field>
            <!-- <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
            </div> -->
        </div>

        <div>
            <mat-form-field appearance="outline" required="true" hideRequiredMarker="false">
                <mat-label>Password</mat-label>
                <input matInput name="Password" [type]="hidePassword ? 'password': 'text'" autocomplete="on" formControlName="password" (focus)="onFocus()" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                    {{ hidePassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>

            <!-- <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div> -->
            </mat-form-field>
        </div>

        <div>
            <button type="submit" [disabled]="loginForm.invalid || ! connected" mat-raised-button color="primary" aria-label="Click to sign In" matTooltip="Click to sign in">
                Sign In
            </button>
            <mat-spinner *ngIf="loading" color="primary" [diameter]="25"></mat-spinner>
        </div>

        <div>
            <mat-error ng-show="errorMsg">{{ errorMsg }}</mat-error>
        </div>
    <!--         <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
    </form>
</div>