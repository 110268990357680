import {
    Component,
    Input,
}                          from '@angular/core';

import { IconsMaterial }   from '@Icons/';
import { Connection }      from '@ObjElements/devices/attributes/connection/';

import { ButtonComponent } from './button.component';


@Component({
   selector:     'qp-connection-icon',
   templateUrl:  'connection-icon.component.html',
   styleUrls:   ['connection-icon.component.css']
})
export class ConnectionIconComponent extends ButtonComponent
{
    private _icon: string = "";
    private _type: string = "";


    @Input()
    public  connection:  Connection;


    //
    // Getters and setters
    //
    @Input()
    public get icon(): string
    {
        return (this._icon)
            ? this._icon
            : ConnectionIconComponent.getIcon(this.type); // getIcon
    }

    public set icon(d)
    {
        this._icon = d;
    }


    @Input()
    public get type(): string
    {
        return (this._type)
            ? this._type
            : ((this.connection instanceof Connection)
                ? this.connection.type
                : this._type
            );
    }

    public set type(d: string)
    {
        this._type = d;
    }


    //
    // Public methods
    //
    private static getIcon(d: string | Connection): string
    {
        switch ((d instanceof Connection) ? d.type : d) {
            case 'mobile': return IconsMaterial.connection_mobilcell;
            case 'wifi':   return IconsMaterial.connection_wifi;
            
            default:       return "";
        } // switch
    }
}