import {
    Component,
    EventEmitter,
    Input,
    Output
}                             from '@angular/core';
import { FormControl }        from '@angular/forms';
 
import {
    Observable,
    Subscription,
    
    filter,
    take
}                             from 'rxjs';

import { BaseComponent }      from '@Base/';
import { ListDataAttributes } from '@GuiElements/';
import {
    MatRow,
    MatSelectChange,
    PageEvent,
    Sort,
    SortDirection
}                             from  '@Material/';
import {
    ClientConfigService,
    DataServiceEvents,
    DataServiceI
}                             from '../Services/';


@Component({
    selector:     'qp-list',
    templateUrl:  'list.component.html',
    styleUrls:   ['list.component.css']
})
export class ListComponent extends BaseComponent
{
    private static readonly PageSizeDefault:  number                          = 25
    // [TBD} need to pull from Device but check circular
    private static readonly Mobilephone:      string                          = "mobilephone";

    public           attrsSrc:                ListDataAttributes[] | string[] = [];
    public           _dataSrc:                any[]                           = [];//MatTableDataSource<any> = new MatTableDataSource();
    public           _dataQuantity:           number                          = 0;
    public           _deviceMobilephonesOnly: boolean                         = false;

    public           refreshEnabled:          boolean                         = false;

    private          initialised:             boolean                         = false;
    public           list:                    FormControl                     = new FormControl();
    private          _data:                   { [key: string]: any }          = {};

    private          _attrs:                  ListDataAttributes[] | string[];
    private          _vals:                   any[] | undefined;


    // @Input()
    // public          select:    string;


    @Input()
    public readonly  checked:       any[];

    @Input()
    public readonly  order:         string;

    @Input()
    public readonly  orderDesc:     boolean;

    @Input()
    public           pageSize:      number                   = ListComponent.PageSizeDefault;

    @Input()
    public readonly  service:       DataServiceI | undefined;

    @Input()
    public           searchEnabled: boolean                  = true;

    @Input()
    public           selectEnabled: boolean                  = false;


    @Input()
    public           title:         string;

    // @Input()
    // public readonly  _vals:      any[];

    @Output()
    public           selected:      EventEmitter<MatRow>     = new EventEmitter();

    @Output()
    public           selectedM:     EventEmitter<MatRow[]>   = new EventEmitter();


    constructor(private readonly ClientConfigService: ClientConfigService)
    {
        super();
    }

    
    //
    // Getters
    //
    @Input()
    public get attrs(): ListDataAttributes[] | string[]
    {
        return this._attrs;
    }

    public set attrs(d: ListDataAttributes[] | string[])
    {
        this._attrs = d;
        this.updateDisplay();
    }

    
    @Input()
    public get vals(): any[] | undefined
    {
        return this._vals;
    }

    public set vals(d: any[] | undefined)
    {
        this._vals = d;
        this.getData();
    }


    public get dataQuantity(): number
    {
        return this._dataQuantity;
    }

    public set dataQuantity(d: number)
    {
        this._dataQuantity = d;
    }


    public get dataSrc(): any[]
    {
        return this._dataSrc;
    }

    public set dataSrc(d: any[])
    {
        this._dataSrc = d ? d : [];
    }


    public get dataTypes(): string[]
    {
        return Object.keys(this._data).sort();
    }


    private set data(d: string[])
    {
        if (Array.isArray(d)) {
            const d2: { [key: string]: any } = {};
            d.forEach((s: string): void => {
                if (s) {
                    this.clearData(s);
                    d2[s] = {};
                }
            }); // forEach
            this._data = d2;
        }
        else this.clearData();
    }


    //
    // Public functions
    //

    // Override
    public override cleanUp(): void
    {
        super.cleanUp();

        this.clearData();
    }


    public filterChanged(e: any): void
    {
        console.debug("Filter changed");
        console.debug(e);
    }


    public pageChanged(e: PageEvent): void
    {
         // Make sure it doesn't constantly update as it will then continuously trigger list-data to request a data refresh
        // if (e && this.pageSize != e.pageSize) this.pageSize = e.pageSize;

        // console.debug(e.length + ", " + e.pageIndex + ", " + e.pageSize + ", " + e.previousPageIndex);

        // Call refresh with start and end data
        this.refresh(e.pageIndex * e.pageSize, e.pageSize);
    }


    public refresh(start?: number, quantity?: number): void
    {
        if (this.service) (start || quantity) ? this.service.refresh2(start, quantity) : this.service.refresh();
    }


    public select(row: MatRow): void
    {
        this.selected.emit(row);
    }

    public selectM(rows: MatRow[]): void
    {
        this.selectedM.emit(rows);
    }


    public selectionChanged(event: MatSelectChange): void
    {
        if (event) {
            if (event.value.length <= 0) this.clearData(undefined, false);
            else {
                event.value.forEach((t: string): void => {
                    this.getData(t);
                }); //forEach
            }

            // Get list of all elements not in event and remove their subscription and data
            const diff = this.dataTypes.filter(x => ! event.value.includes(x)).forEach((t: string): void => {
                this.clearData(t, false);
            }); // filter

            this.updateDisplay();
        }
    }


    public sortChanged(e: Sort): void
    {
        const asc:  string = 'asc';
        const desc: string = 'desc';

        if (e) {
            if      (e.direction == asc)  console.debug("Sort " + e.active + " - ascending");
            else if (e.direction == desc) console.debug("Sort " + e.active + " - descending");
            else                          console.debug("Sort " + e.active + " - none");
        }
         // Make sure it doesn't constantly update as it will then continuously trigger list-data to request a data refresh
        // if (e && this.pageSize != e.pageSize) this.pageSize = e.pageSize;

        // console.debug(e.length + ", " + e.pageIndex + ", " + e.pageSize + ", " + e.previousPageIndex);

        // Call refresh with start and end data
        // this.refresh((e.pageIndex * e.pageSize), e.pageSize);
    }


    //
    // Protected functions
    //
    protected override initialise(): void
    {
        super.initialise();

        // if (! this.title) this.title = "List";

        console.debug("Initialising List component for " + this.title);

        if (this.ClientConfigService) {
            this.sub = this.ClientConfigService.deviceMobilephonesOnly$.subscribe((required: boolean): void => {
                    console.debug("ClientConfig msg received - title: " + this.title + ", deviceMobilephonesOnly: " + required)
                    this._deviceMobilephonesOnly = required;
                }); // subscribe

            this.sub = this.ClientConfigService.refreshButtonEnabled$.subscribe((required: boolean): void => {
                    console.debug("ClientConfig msg received - title: " + this.title + ", refreshRequired: " + required)
                    this.refreshEnabled = required;
                }); // subscribe
        }

        if (this.service) {
            // Check for sub-types (e.g. DeviceCollectionService)
            // if (this.service[DataServiceEvents.status]) {
                let obs: Observable<any> | undefined = this.service[DataServiceEvents.status];
                if (obs instanceof Observable) this.sub = obs.subscribe((d: string[] | object): void => {
                    if (d) {
                        if (! Array.isArray(d)) {
                             // Sub-types
    // console.debug("xxxx");
    // console.debug(this._data);
    // console.debug(this.dataTypes);
    // console.debug(Object.keys(d).sort())
    // console.debug(this.dataTypes === Object.keys(d).sort())
                            if (this._deviceMobilephonesOnly) {
                                if (Object.keys(d).includes(ListComponent.Mobilephone)) this.data = [ListComponent.Mobilephone];
                            }
                            else {
                                if (this.dataTypes !== Object.keys(d).sort()) this.data = Object.keys(d);
                            }
    // console.debug(this._data);

                            // [TBD] Work out which data remaining had data, auto-reload

                            if (this.dataTypes.length > 0) {
                                if (this.dataTypes.length === 1) this.setData(this.dataTypes[0])
                                this.updateDisplay();
                            }
                        }
                        else {
                             // No sub-types; just get data
                            this.getData();
                        }
                    }
                }); // subscribe

                obs = this.service[DataServiceEvents.number];
                if (obs instanceof Observable) this.sub = obs.subscribe((d: number): void => {
                        this.dataQuantity = d;
                    }); // subscribe

                if (! this.initialised) {
                    obs = this.service[DataServiceEvents.loading];
                    if (obs instanceof Observable) this.sub = obs
                        .pipe(
                            filter((d: boolean): boolean => ! d)
                        )
                        .pipe(
                            take(1)
                        )
                        .subscribe((d: boolean): void => {
                            this.refresh(0, this.pageSize);
                            this.initialised = true;
                        }); // subscribe
                }
            // }
        } // this.service
    }


    //
    // Private functions
    //
    private clearData(t?: string, del: boolean = true): {}
    {
        (t ? [t] : (this.dataTypes ? this.dataTypes : [])).forEach((s: string): void => {
            if (this._data[s]) {
                if (this._data[s].sub instanceof Subscription) this._data[s].sub.unsubscribe();

                if (del) delete this._data[s];
                else     this._data[s] = {};
            }
        }); // forEach

        return this._data;
    }


    private getData(t?: string)
    {
        if (Array.isArray(this.vals)) {
            this._data['data'] = {data: this.vals};
            if (! this.dataQuantity) this.dataQuantity = this.vals.length;
            this.updateDisplay();
        }

        else if (this.service && (! t || ! this._data[t] || ! this._data[t].sub)) {
            const obs: Observable<any | undefined> | undefined = t
                ? this.service.getObservable(t, DataServiceEvents.data)
                : this.service[DataServiceEvents.data];

            if (obs instanceof Observable) {
                if (this._data[t ? t : 'data'] && this._data[t ? t : 'data'].sub) this._data[t ? t : 'data'].sub.unsubscribe();
                this._data[t ? t : 'data'] = {};
                this._data[t ? t : 'data'].sub = obs.subscribe((d: object[]): void => {
                    if (Array.isArray(d)) {
                        // console.error(this._data)
                        // console.error(d)
                        this._data[t ? t : 'data'].data = d;
                        if (! this.dataQuantity) this.dataQuantity = d.length;
                        this.updateDisplay();
                    }
                }); // subscribe
            } // obs
        }
    }


    private setData(t: string | string[] | undefined): any
    {
        if (this.list && t) {
            const types: string[] = Array.isArray(t) ? t : [t];
            this.list.setValue(types);
            if (Array.isArray(types)) types.forEach((s: string): void => {
                this.getData(s);
            }); // forEach

            return this.list.value;
        }

        return undefined;
    }


    private sortNumber(a: number, b: number): number
    {
        return a - b;
    }


    private sortStr(a: string, b: string): number
    {
        return (a && b)
            ? (a > b
                ? 1
                : (a < b
                     ? -1
                     : 0
                  )
            )
            
            : 0;
    }


    private updateDisplay(): void
    {
        // Data attrs
        let dataNew: any[] = [];
        if (Array.isArray(this.dataTypes)) this.dataTypes
            // Sort alphabetically by key - needed?
            // .sort((a: string, b: string): number => {
            //     return a.localeCompare(b);
            // })
            .forEach((d: string): void => {
                if (this._data[d] && this._data[d].data) dataNew = dataNew.concat(this._data[d].data);
// console.error(this._data[d])

            }); // forEach


// console.error(this.attrs)
        // Set heading attrs
        this.attrsSrc = this.attrs;//(this.service ? (this.service as any).attrs : null);
        if      (this.attrsSrc && this.attrsSrc.length) this.attrsSrc = this.attrs;
        // Get attrs from first data if required object (if there is one)
        else if (dataNew && dataNew.length)             this.attrsSrc = Object.keys(dataNew[0]);

        this.dataSrc =
            this.order
                ? dataNew.sort((a, b) => this.orderDesc ? this.sortStr(b[this.order], a[this.order]) : this.sortStr(a[this.order], b[this.order]))
                : dataNew; // only set once to avoid display blanking
    }
}