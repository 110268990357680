import { CommonModule }         from '@angular/common';
import { NgModule }             from '@angular/core';
import { RouterModule }         from '@angular/router';

// qp-window-header for SecureLoginComponent component
import { GuiElementsModule }    from '@GuiElements/';

// qp-footer
// import { HeaderModule }         from '@Header/';
import { MaterialCustomModule } from '@Material/';

import { LoginComponent }       from './Standard/login.component';
import { SecureLoginComponent } from './Secure/securelogin.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        // HeaderModule,
        GuiElementsModule,
        MaterialCustomModule
    ],
    
    declarations: [
        LoginComponent,
        SecureLoginComponent
    ],

    providers: [
    ],

    exports: [
    ]
})
export class LoginModule { }