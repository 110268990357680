import {
    Component,
    EventEmitter,
    Output
}                          from '@angular/core';

import { ButtonComponent } from './button.component';


@Component({
    template: ''
})
export class MenuComponent extends ButtonComponent
{
    @Output()
    public selected: EventEmitter<string> = new EventEmitter(); // used to inform parents that button pressed


    //
    // Public functions
    //
    public select($event: MouseEvent, state: string): void
    {
        this.selectFn($event, state);
    }


    //
    // Private functions
    //
    private selectFn($event: MouseEvent, state: string): void
    {
        this.selected.emit(state);
    }
}