import { Injectable }  from '@angular/core';
import { 
    BehaviorSubject,
    Observable
}                      from 'rxjs';

import { BaseService } from '@Base/';


export interface HeaderDisplayOptions
{
    about:         boolean,
    all:           boolean,
    devices:       boolean,
    deviceGroups:  boolean,
    displayMode:   boolean,
    notifications: boolean,
    settings:      boolean,
    user:          boolean
}


@Injectable({
    providedIn: 'root'
})
export class HeaderService extends BaseService
{
    private static readonly _defaultOptions: HeaderDisplayOptions = {
        about:         false,
        all:           false,
        devices:       false,
        deviceGroups:  false,
        displayMode:   true,
        notifications: false,
        settings:      false,
        user:          false
    }; // _defaultOptions

    private _displayOptions: BehaviorSubject<HeaderDisplayOptions>;
 
    
    constructor()
    {
        super();

        this._displayOptions = new BehaviorSubject<HeaderDisplayOptions>(HeaderService._defaultOptions);
    }


    public get displayOptions(): Observable<HeaderDisplayOptions> | undefined
    {
        return this._displayOptions instanceof BehaviorSubject
            ? this._displayOptions.asObservable()
            : undefined;
    }
    

    // Set by MainComponent
    public set all(d: boolean)
    {
        HeaderService._defaultOptions.all = d;
        if (this._displayOptions instanceof BehaviorSubject) this._displayOptions.next(HeaderService._defaultOptions);
    }


    public set user(d: boolean)
    {
        HeaderService._defaultOptions.all  = false;
        HeaderService._defaultOptions.user = d;
        if (this._displayOptions instanceof BehaviorSubject) this._displayOptions.next(HeaderService._defaultOptions);
    }
}