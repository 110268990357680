import { Injectable }  from '@angular/core';
import {
    BehaviorSubject,
    Observable
}                      from 'rxjs';

import { BaseService } from '@Base/';


@Injectable({
    providedIn: 'root'
})
export class DisplayModeService extends BaseService
{
    // Need to match CSS classes defined in theme-*.scss
    private static readonly themeDark  = "theme-dark";
    private static readonly themeLight = "theme-light";

    private _mode$: BehaviorSubject<boolean>;


    constructor()
    {
        super();

        this._mode$ = new BehaviorSubject<boolean>(false); // initially no dark mode
    }


    //
    // Getters
    //
    get mode$(): Observable<boolean>
    {
        return this._mode$.asObservable();
    }


    //
    // Public methods
    //
    public update(d: boolean): void
    {
        this._setDisplayMode(d);
        this._mode$.next(d);
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this._mode$ instanceof BehaviorSubject) this._mode$.complete();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising DisplayMode service");
    }


    //
    // Private methods
    //
    private _setDisplayMode(darkMode: boolean): void
    {
        const cl: any = document.body.classList;
        if (cl) {
            if (darkMode) {
                // Set to dark
                if (! cl.contains(DisplayModeService.themeDark)) cl.add(DisplayModeService.themeDark);
                cl.remove(DisplayModeService.themeLight);

            }
            else {
                // Set to light
                if (! cl.contains(DisplayModeService.themeLight)) cl.add(DisplayModeService.themeLight);
                cl.remove(DisplayModeService.themeDark);
            }
        }
    }
}