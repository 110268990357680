import { Component }       from '@angular/core';

import { IconsMaterial }   from '@Icons/';

import { ButtonComponent } from './button.component';


@Component({
   selector:     'qp-map-button',
   templateUrl:  'map-button.component.html',
   styleUrls:   ['map-button.component.css']
})
export class MapButtonComponent extends ButtonComponent
{
   public readonly icon: string = IconsMaterial.map;
}