import { Injectable }  from '@angular/core';
import {
    Observable,
    Subject,
    Subscriber,
    Subscription,

    filter
}                                    from 'rxjs';

import { BaseService }               from '@Base/';
import { ElementCommon as ElementC } from '@ObjElements/';

import {
    Messages,
    MessageInterface
}                                    from './message.service';


const _useNewObs = true;

@Injectable({
    providedIn: 'root'
})
export class MessageProcessingService extends BaseService
{
    private static readonly alarmStatusPeriod: number = 20; // secs

    private static readonly _subjects: string[] = [
        Messages.msgTypesInfo.clientconfig,
        Messages.msgTypesInfo.devicegroups,
        Messages.msgTypesInfo.devices,
        Messages.msgTypesInfo.devicenotifications,
        Messages.msgTypesInfo.geolocation,
        Messages.msgTypesInfo.groupinstances,
        Messages.msgTypesInfo.networklist,

        Messages.events.device,
        Messages.events.devicegroup,
        Messages.events.notification,
        Messages.events.survey
    ];

    private _subjects$: Record<string, Subject<any>> = {};


    constructor()
    { 
        super();

        // Create RxJS subjects
        MessageProcessingService._subjects.forEach((d: string): void => {
            if (d) this._subjects$[d] = new Subject<any>();    
        }); // forEach
    }


    //
    // Public functions
    //
    // Register for all messages of this type
    public getObs$(d: string): Observable<any> | undefined
    {
        if (_useNewObs) {
            return this.getObsReg$(d, undefined);
        }
        else {
            const s: Subject<any> | undefined = this.getSubject(d);
            return (s instanceof Subject) ? s.asObservable() : undefined;
        }
    }

    // Register for all messages of this type filtered by ID, and enabled on the server
    public getObsReg$(d: string, ids: string[] | undefined): Observable<any> | undefined
    {
        const s: Subject<any> | undefined = this.getSubject(d);
        return (s instanceof Subject)
            ? new Observable(
                function subscribe(subscriber: Subscriber<any>): any {
                    const sub: Subscription = s
                        .pipe(
                            filter(d => !!(! Array.isArray(ids)
                                 || d && (ids.includes(d[ElementC.attrs._id]) || ids.includes(d[Messages.attributes.id])) )
                            ) // filter
                            // filter(d => !!(! Array.isArray(ids) || d && ids.includes(d[ElementC.attrs._id])) )
                        ) // pipe

                        .subscribe({
                            next: (d: any): void => {
                                subscriber.next(d)
                            },

                            error: (err: any): void => {
                                subscriber.error(err);
                            },

                            complete: (): void => {
                                subscriber.complete();
                            }
                        }); // subscribe

                    return function unsubscribe(): void {
                        if (sub instanceof Subscription) sub.unsubscribe();
                    }
                } // subscribe functipn
            ) // new Observable
            
            : undefined;
    }


    public process(msg: MessageInterface): void
    {
        // console.debug("Received message - informing appropriate service(s)");
        // console.debug(msg);

        const msgType: string = msg ? msg.message : "";//msg[Messages.msgTypesAttributes.message];

        if (msgType) {
            const msgData: any = msg.data;//msg[Messages.msgTypesAttributes.data];
            let sub: Subject<any> | undefined = undefined;

            if ((sub = this.getSubject(msgType)) instanceof Subject) {
                sub.next(msgData);
            }
            else switch (msgType) {
                // New - Events
                case Messages.events.alarm:
                // Allow fall-through
                case Messages.events.notification:
                    // this.NotificationsService.update(msg.kpialarms);
                    // this.StatusMessageService.set("Custom alarm: " + msg.alarm, StatusMessageService.Level.Warn, MessageProcessingService.alarmStatusPeriod);
                    if ((sub = this.getSubject(Messages.events.notification)) instanceof Subject) sub.next(msgData);
                break;

                // // Allow fall-through below
                // // case Messages.msgTypesInfo.clientconfig:
                // case Messages.events.devicegroup:
                // case Messages.events.survey:
                // case Messages.msgTypesInfo.geolocation:
                // case Messages.msgTypesInfo.groupinstances:
                //     Messages.msgTypesInfo.networklist:
                //     if ((sub = this.getSubject(msgType)) instanceof Subject) sub.next(msgData);
                // break;

                // Allow fall-through below

                case Messages.msgTypesInfo.disconnect:          // Disconnected phone
                case Messages.msgTypesInfo.disconnectwait:      // this.MessageService.messages.msgTypesInfo.disconnecting// Disconnected (pending) phone
                    console.error("DISCONNECT")

                    if ((sub = this.getSubject(Messages.events.device)) instanceof Subject) sub.next(msg);
                    if ((sub = this.getSubject(Messages.msgTypesInfo.devices)) instanceof Subject) sub.next(msg);
                    break;

                case Messages.events.device:                    // New - device update
                    console.error("DEVICE EVENT - should not be here")

                case Messages.msgTypesInfo.devices:             // New devices - in particular, new repeaters
                case Messages.msgTypesInfo.devicenotifications: // Device notification

                case Messages.msgTypesInfo.init:                // New or re-added phone
                    if ((sub = this.getSubject(Messages.msgTypesInfo.devices)) instanceof Subject) sub.next(msg);
    //                        this.deviceSubject$.next(data);
                break;


                // Geolocation request from server; send current browser position
                // case Messages.msgTypesInfo.geolocation:
                //     if ((sub = this.getSubject(Messages.msgTypesInfo.geolocation)) instanceof Subject) sub.next(msg);
                // break;

                // case Messages.msgTypesInfo.groupinstances:
                //     if ((sub = this.getSubject(Messages.msgTypesInfo.groupinstances)) instanceof Subject) sub.next(msg);
                //     //this.qp_DeviceGroupsFactory.updateInstances(data);
                // break;

                // case Messages.msgTypesInfo.networklist:
                //     if ((sub = this.getSubject(msgType)) instanceof Subject) sub.next(msg);
                // break;


                //
                // Legacy
                //
                case Messages.msgTypesInfo.networkscores:
                case Messages.msgTypesInfo.networkkpialarms:
                //case this.MessageService.messages.msgTypesSub.mobilecells:
                case Messages.msgTypesInfo.mobilecellsightings:

                case Messages.msgTypesInfo.testlist:
                case Messages.msgTypesInfo.testresults:
                case Messages.msgTypesInfo.servicelist:
                case Messages.msgTypesInfo.servicesos:
                case Messages.msgTypesInfo.servicespd:
                //case this.MessageService.messages.msgTypesSub.wifiaps:
                case Messages.msgTypesInfo.wifiapsightings:
                    // console.debug(data.message);
                    //$scope.$broadcast(data.message, data);
                break;

                // case this.MessageService.messages.msgTypesSub.mobilecells:
                //     this.qp_CellsFactory.update(data.cells);
                //     break;

                // case this.MessageService.messages.msgTypesSub.wifiaps:
                //     this.qp_WifiFactory.update(data.cells);
                //     break;

                // case this.MessageService.messages.msgTypesSub.devicegroups:
                //     this.qp_DeviceGroupsFactory.updateGroups(data);
                //     break;


                // Special case as window showing this data is not direct sub-child of $scope
                case Messages.msgTypesInfo.deviceevents:
                    //$rootScope.$broadcast(data.message, data);
                break;

                // case Messages.msgTypesInfo.kpisos:
                // case Messages.msgTypesInfo.kpispd:
                //     this.KpisDataService.update(msgType, msg);
                //     //$scope.$broadcast(data.message, data); // temp
                //     // $rootScope.$broadcast(data.message, data); // temp
                // break;

                // case Messages.msgTypesInfo.insights:
                // console.debug(msg);
                // //qp_TimeoutFactory.processViaTimeout(function() {
                //     this.KpisDataService.update('kpisoneshot', msg);
                // //});
                //     //$scope.$broadcast(data.message, data); // temp
                //     //  $rootScope.$broadcast(data.message, data); // temp
                // break;
                
                // case Messages.msgTypesInfo.notifications:
                //     this.NotificationsService.update(msg.kpialarms);
                //     break;


                // case 'customalarm':
                    // if (data.alarm) {
                    //     //console.debug("Received custom alarms: " + data.alarm);
                    //     this.StatusService.setStatus("Custom alarm: " + data.alarm, MessageProcessingService.alarmStatusPeriod);
                    // }
                    // break;


                default:
                    const ssEvent    = "event";
                    const ssProgress = ssEvent + "progress";
                    const ssStart    = ssEvent + "start";
                    const ssStatus   = ssEvent + "status";
                    const ssTest     = "test";
                    
                    console.log(msgType);

                    if (msgType && msgType.substring(0, ssTest.length) == ssTest && msg.testResultType) {
                        if      (msg.testResultType.substring(0, ssStart.length)  == ssStart ||
                                 msg.testResultType.substring(0, ssStatus.length) == ssStatus) {
                            if ((sub = this.getSubject(Messages.msgTypesInfo.devices)) instanceof Subject) sub.next(msg);
                        }
                        // else if (msg.message.substring(0, ssProgress.length) != ssProgress) {
                        //     // Updated phone - don't pass on test progress messages due to client CPU loading
                        // }
                    }
                    else {
                        if ((sub = this.getSubject(Messages.msgTypesInfo.devices)) instanceof Subject) sub.next(msg);
                    }
            } // switch
        }
    }    


    //
    // Protected functions
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        Object.values(this._subjects$).forEach((d: Subject<any>): void => {
            if (d instanceof Subject) d.complete();
        }); // forEach
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising MessageProcessing service");
    }


    //
    // Private functions
    //
    private getSubject(d: string): Subject<any> | undefined
    {
        return (d && this._subjects$[d] instanceof Subject) ? this._subjects$[d] : undefined;
    }
}