import { CommonModule }          from '@angular/common';
// import {
//     downgradeInjectable,
//     UpgradeComponent
// }                                from '@angular/upgrade/static';
import {
    // Directive,
    // ElementRef,
    // Injector,
    NgModule
}                                from '@angular/core';

import { InsightsWindowService } from './insights-window.service';

// declare let angular: angular.IAngularStatic;


// @Directive( {selector: 'qp-insights'} )
// export class AngularJSComponent2 extends UpgradeComponent
// {
//     constructor(private readonly ref: ElementRef,
//                 private readonly inj: Injector)
//     {
//         super('qpInsights', ref, inj);
//     }
// }

 
@NgModule({
    imports: [
        CommonModule
    ],
    
    declarations: [
        // AngularJSComponent2
    ],

    providers: [
    ],

    exports: [
        // AngularJSComponent2
    ]
})
export class InsightsModule { }


// angular
//     .module('qpProMobileApp')
//     .factory('qp_WindowInsightFactory', downgradeInjectable(InsightsWindowService));