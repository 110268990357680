import { Injectable }                     from '@angular/core';
import { MatDialogRef }                   from "@angular/material/dialog";
import {
    of,
    Subject,

    delay,
    filter,
    take,
    takeUntil,
    tap
}                                         from 'rxjs';

import { BaseService }                    from '@Base/';

import { ProgressSpinnerDialogComponent } from '../Components/';
import { DialogService }                  from './dialog.service';


@Injectable({
    providedIn: 'root'
})
export class ProgressSpinnerDialogService extends BaseService
{
    private static readonly startDelay:     number                                                   = 500; // ms

    private                 _dialogRef:     MatDialogRef<ProgressSpinnerDialogComponent> | undefined;
    private                 _dialogRefStop: Subject<boolean>                                         = new Subject<boolean>();
 
 
    public constructor(private readonly DialogService: DialogService)
    {
        super();
    }


    //
    // Getters and setters
    //
    private get dialogRef(): MatDialogRef<ProgressSpinnerDialogComponent> | undefined
    {
        return this._dialogRef;
    }


    private set dialogRef(d: MatDialogRef<ProgressSpinnerDialogComponent> | undefined)
    {
        if (this._dialogRef instanceof MatDialogRef) this._dialogRef.close();
        this._dialogRef = d;
    }


    //
    // Public methods
    //
    public show(immediate?: boolean): void
    {
        if (immediate) this.dialogRef = this.open()
        else {
            this.sub = of(true)
                .pipe(
                    delay(ProgressSpinnerDialogService.startDelay)
                )
                .pipe(
                    take(1)
                )
                .pipe(
                    takeUntil(this._dialogRefStop)
                )
                .pipe(
                    filter((d: boolean): boolean => d)
                )
                .pipe(
                    tap((): void => {
                        this.dialogRef = this.open();
                    })
                )
                .subscribe();
        }
    }


    public clear(): void
    {
        this.dialogRef = undefined;
        this._dialogRefStop.next(false);
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this.clear();

        this._dialogRefStop.complete();
    }


    // // Override
    // protected override initialise(): void
    // {
    //     super.initialise();

    //     console.debug("Initialising ProgressSpinnerDialog service");
    // }


    private open(): MatDialogRef<ProgressSpinnerDialogComponent> | undefined
    {
        return this.DialogService.showDialog(
            ProgressSpinnerDialogComponent, // component
            undefined,                      // height
            undefined,                      // width
            {
                disableCloseButton: true
            },                              // component data
            true,                           // disableClose
            false,                          // enableOk
            true                            // transparent
        ); // showDialog - start spinner
    }
}