<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User" matTooltip="{{ user.name }}">
    <mat-icon>{{ iconUser }}</mat-icon>
    <!-- <mat-icon>{{ iconArrowDown }}</mat-icon> -->
</button>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item [disabled]="true">
            <mat-icon>{{ iconPerson }}</mat-icon>
            <span>{{ user.name }}</span>
        </button>

        <mat-divider></mat-divider>

        <button mat-menu-item (click)="logout($event)">
            <mat-icon>{{ iconLogout }}</mat-icon>
            <span>Sign out</span>
        </button>
    </ng-template>
</mat-menu>