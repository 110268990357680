import { Injectable }  from '@angular/core';
import { Router }      from '@angular/router';
import {
    Observable,
    Subject,
    
    filter,
    take
}                      from 'rxjs';

import { BaseService } from '@Base/';
import {
    Messages,
    MessageService
}                      from '@Messaging/';

import { routeNames }  from '../app.routing.names';

import { 
    User,
    UserService
}                      from './user.service';


export interface AuthenticationRsp
{
    msg?: string,
    user: User | undefined
}


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends BaseService
{
    private static readonly currentUserKey:            string  = 'currentUser';
    private static readonly defaultLoginFailureCause1: string  = "Invalid login details";
    private static readonly defaultLoginFailureCause2: string  = "Unable to login";
    

    private _configured:                               boolean = false;
    private _currentUserSubject:                       Subject<AuthenticationRsp>;
    private _user:                                     User | undefined;


    constructor(private readonly Router:         Router,
                private readonly MessageService: MessageService,
                private readonly UserService:    UserService)

    {
        super();

        this._currentUserSubject = new Subject<AuthenticationRsp>();
    }


    // Getters and setters
    //
    public get isConfigured(): boolean
    {
        return this._configured;
    }


    //
    // Public methods
    //

    // Called by (overarching) AppComponent to trigger call to UserService;
    // can't do in initialise() as a service can't access another service
    // during initialise(), which here is called by constructor
    public configure(force: boolean = false): void
    {
        if (! this.isConfigured || force) {
            // Subscribe to be informed about socket (re)connection; if/when it
            // it happens, refresh this service to ensure is up-to-date
            if (this.MessageService && this.MessageService.connected$ instanceof Observable) this.sub = this.MessageService.connected$
                // .pipe(filter((con: boolean): boolean => con == true))
                .subscribe((con: boolean): void => {
                    // Initial connection
                    if (! this.isConfigured) {
                        if (con) {
                            this.configured();
                            this._configured = true;
                        }
                    }

                    // Subsequent reconnection
                    else {
                        if (con) {
                            if (this._user) {
                                this.login(this._user.username, undefined, this._user.token); // No password for this
                                console.info("Notified server of login (reconnect): " + this._user.username + ", " + this._user.token);
                            }
                        }
                        else if (this.UserService.webLogoutOnDisconnect) {
                            this.logout();
                        }
                    }
            }); // connected subscribe

            // if (this.UserService) this.sub = this.UserService.user
            //     .subscribe((d: User): void => {
            //         if (d instanceof User && d.isValid) {
            //             this._user = d;
            //             // If user service gets local storage details, need to inform server
            //             // so socket is added to correct room
            //             this.login(this._user.username, undefined, this._user.token); // No password for this
            //             console.info("Notified server of login (existing): " + this._user.username + ", " + this._user.token);
            //         }
            // }); // subscribe

            console.debug("Configured AuthenticationService (force = " + force + ")");
        }
        else {
            console.warn("AuthenticationService already configured");
        }
    }


    public login(user: string | User, password?: string, token?: string): Observable<AuthenticationRsp> | undefined
    {
        const defaultLoginCause = "Invalid login details";

        let username: string;
        if (user instanceof User) {
            username = user.username;
            token    = user.token;
        }
        else {
            username = user;
        }

        if (username) {
            // Use observable
            const obs: Observable<any> | undefined = this.MessageService
                ? this.MessageService.sendMsg(
                    Messages.msgTypesInfo.login,
                    {
                        [Messages.msgTypesAttributes.username]: username,
                        [Messages.msgTypesAttributes.password]: password,
                        [Messages.msgTypesAttributes.token]:    token
                    }
                ) // sendMsg

                : undefined;

            if (obs instanceof Observable) {
                // if (token) {
                //     console.log("TTT 0");
                //     // [TBD] If token, informing server of existing login
                // }
                // else {
                    // New login
                    this.sub = obs
                        .pipe(take(1))
                        .subscribe({
                            next: (d: any): void => {
                                const u: User | undefined =
                                    this.UserService && this.UserService.update instanceof Function
                                        ? this.UserService.update(d)
                                        : undefined;
                                const ar: AuthenticationRsp =
                                    u instanceof User && u.isValid
                                        ? { user: u }
                                        : {
                                            msg: d && d[Messages.msgTypesAttributes.cause]
                                                ? d[Messages.msgTypesAttributes.cause] as string
                                                : AuthenticationService.defaultLoginFailureCause1,
                                            user: undefined
                                        };
                                if (ar) this._currentUserSubject.next(ar);
                            },

                            error: (e: any): void => {
                                this._currentUserSubject.next(
                                    {
                                        msg:  AuthenticationService.defaultLoginFailureCause2,
                                        user: undefined
                                    } as AuthenticationRsp
                                ); // next
                            }
                        }); // subscribe
                // }
            }
            else {
                console.error("Unable to send msg: " + Messages.msgTypesInfo.login + ",  " + username + ", " + token);
            }
        } // if

        return this._currentUserSubject instanceof Subject ? this._currentUserSubject.asObservable() : undefined;
    }


    public logout(redirect: boolean = true): Observable<any> | undefined
    {
        // Only do if already logged in
        if (this._user) {
            // Inform server
            const obs: Observable<any> | undefined = this.MessageService
                ? this.MessageService.sendMsg(
                    // ? this.MessageService.sendMsgCommon(
                    Messages.msgTypesInfo.logout,
                    {
                        [Messages.msgTypesAttributes.username]: this._user instanceof User ? this._user.username : undefined
                    }
                ) // sendMsgCommon

                : undefined;

            if (obs instanceof Observable) this.sub = obs
                .pipe(take(1))
                .subscribe(
                    (d: any): void => {
                        console.debug(d);

                        // No need to do anything else
                    }
                ); // subscribe

            if (this.UserService && this.UserService.logout instanceof Function) this.UserService.logout();
            if (redirect) this.logout2();
        }
        
        return this._currentUserSubject instanceof Subject ? this._currentUserSubject.asObservable() : undefined;
    }


    //
    // Private methods
    //
    private configured(): void
    {
        if (this.UserService) {

            // Subscribe for user changes
            if (this.UserService.user$ instanceof Observable) this.sub = this.UserService.user$
                .subscribe((d: User | undefined): void => {
                    if (d instanceof User && d.isValid) {
                        this._user = d;
                        // If user service gets local storage details, need to inform server
                        // so socket is added to correct room
        // this.login(this._user.username, undefined, this._user.token); // No password for this
        // console.info("Notified server of login (" + (this.isConfigured ? "existing" : "new") + ": " + this._user.username + ", " + this._user.token);
                    }
                    else {
                        // Assume logging out if not requested from this service
                        this.logout2();
                    }
                }); // subscribe
            
            // Subscribe for user timeout notification
            if (this.UserService.userTimeout$ instanceof Observable) this.sub = this.UserService.userTimeout$
                .subscribe((d: boolean): void => {
                    if (d) this.logout();
                }); // subscribe
        }
    }


    // If user requests logout, calls logout() above which sends msg to server
    // If server requests logout, clears UserService, which then triggers logout2() - no need for server msg
    // If timeout triggers logout, OBS above triggered which calls logout() to inform server


    private logout2(): Observable<any> | undefined
    {
        // if (this.UserService && this.UserService.logout instanceof Function) this.UserService.logout();
        this.Router.navigate([routeNames.login], {});
    
        return this._currentUserSubject instanceof Subject ? this._currentUserSubject.asObservable() : undefined;
    }
}