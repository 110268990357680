<div class="mat-button">
    <button  mat-button
        matTooltip="Connection: {{ type }}"
        matTooltipClass="tooltip-multiline"
        #tt="matTooltip"
        (click)="tt.show()">

        <mat-icon class="icon-1-5x" aria-label="Connection">{{ icon }}</mat-icon>
    </button>
</div>

        <!-- [ngClass]="is5g() ? 'nr' : technology" -->
